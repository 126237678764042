<template>
  <div>
    <div v-if="loading" class="text-center">
      <v-progress-circular
        :size="200"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <br />
      <br />
      <div class="text-h4">Cargando</div>
    </div>
    <div v-else>
      <v-row justify-sm="center">
        <v-card
          v-if="!vinculado && false"
          class="ma-0 pa-0 mt-4"
          width="95%"
          elevation="0"
        >
          <v-alert shaped outlined color="red" class="mb-0 py-2">
            <v-row align="center">
              <v-col class="grow">
                <v-icon color="red"> mdi-alert </v-icon>
                Su usuario no está asociado a un Empleado. Por favor, contacte a
                los responsables de Recursos Humanos para realizar la
                asociación.
              </v-col>
            </v-row>
          </v-alert>
        </v-card>
        <v-card
          v-else-if="!expediente_valido"
          class="ma-0 pa-0 mt-4"
          width="95%"
          elevation="0"
        >
          <v-alert shaped outlined color="red" class="mb-0 py-2">
            <v-row align="center">
              <v-col class="grow">
                <v-icon color="red"> mdi-alert </v-icon>
                Su expediente personal está incompleto o contiene información
                inconsistente.
              </v-col>
              <v-col class="shrink">
                <v-btn
                  class="mx-0"
                  dark
                  color="red"
                  small
                  @click="gotoExpediente"
                >
                  <v-icon class="mr-2"> mdi-arrow-right-bottom </v-icon>
                  Revisar Mi Expediente
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-card>
      </v-row>

      <v-item-group
        v-if="selector_grupos == true && id_vista_grupo_actual == null"
        style="display: grid !important"
        v-model="id_vista_grupo_actual"
      >
        <v-card
          class="ma-0 pa-0 mt-8 align-self-center"
          width="95%"
          elevation="0"
          style="justify-self: center !important"
        >
          <v-row style="justify-content: center !important">
            <v-col
              v-for="(vg, idx) in vistaGrupos"
              :key="'vg' + idx"
              cols="12"
              md="2"
            >
              <v-item v-slot="{ active, toggle }" :value="vg.id_vista_grupo">
                <v-card
                  :color="active ? 'primary' : ''"
                  class="d-flex align-center"
                  height="150"
                  @click="toggle"
                >
                  <v-scroll-y-transition>
                    <div
                      v-if="active"
                      class="text-h6 flex-grow-1 text-center white--text"
                    >
                      <v-icon :size="64" color="white">
                        {{ vg.icono }}
                      </v-icon>
                      <br />
                      {{ vg.nombre }}
                    </div>
                    <div v-else class="text-h6 flex-grow-1 text-center">
                      <v-icon :size="64">
                        {{ vg.icono }}
                      </v-icon>
                      <br />
                      {{ vg.nombre }}
                    </div>
                  </v-scroll-y-transition>
                </v-card>
              </v-item>
            </v-col>
          </v-row>
        </v-card>
      </v-item-group>

      <v-row
        justify-sm="center"
        v-if="selector_grupos == false || (selector_grupos == true && id_vista_grupo_actual != null)"
      >
        
        <v-card class="mt-4" width="95%" fill>
          <v-btn
            v-if="selector_grupos"
            block
            small
            text
            color="primary"
            @click="id_vista_grupo_actual = null"
            class="my-2"
          >
            <v-icon dark>
              mdi-arrow-left
            </v-icon>
            Regresar
          </v-btn>

          <v-tabs background-color="primary" grow dark v-model="tab">
            <v-tab key="admision" href="#admision" v-if="mostrarVista(1)">
              Admisión de <br />
              Pacientes
            </v-tab>

            <v-tab
              v-if="mostrarVista(2)"
              key="consulta_medica"
              href="#consulta_medica"
              @click="actualizarItems('consulta_medica')"
            >
              Consulta <br />
              Medica
            </v-tab>

            <v-tab
              v-if="mostrarVista(9)"
              key="consulta_dental"
              href="#consulta_dental"
              @click="actualizarItems('consulta_dental')"
            >
              Consulta <br />
              Dental
            </v-tab>

            <v-tab
              v-if="mostrarVista(10)"
              key="consulta_nutricional"
              href="#consulta_nutricional"
              @click="actualizarItems('consulta_nutricional')"
            >
              Consulta <br />
              Nutricional
            </v-tab>

            <v-tab
              v-if="mostrarVista(13)"
              key="promociones"
              href="#promociones"
              @click="actualizarItems('promociones')"
            >
              Promoción <br />
              de la Salud
            </v-tab>

            <v-tab
              v-if="mostrarVista(3)"
              key="pacientes"
              href="#pacientes"
              @click="actualizarItems('pacientes')"
            >
              Pacientes
            </v-tab>

            <v-tab v-if="mostrarVista(11)" key="almacen" href="#almacen">
              Farmacia <br />
              UMM
            </v-tab>

            <v-tab v-if="mostrarVista(7)" key="pagos" href="#pagos">
              Solicitudes <br />
              de pago
            </v-tab>

            <v-tab
              v-if="mostrarVista(18) || vinculado"
              key="recursos_humanos"
              href="#recursos_humanos"
            >
              Recursos <br />
              Humanos
            </v-tab>

            <v-tab
              v-if="mostrarVista(16)"
              key="incidencias"
              href="#incidencias"
            >
              Incidencias
            </v-tab>

            <v-tab v-if="mostrarVista(17)" key="reportes" href="#reportes">
              Reportes
            </v-tab>

            <v-tab v-if="mostrarVista(4)" key="catalogos" href="#catalogos">
              Cátalogos
            </v-tab>

            <!--
              <v-tab
                key="mantenimientos"
                href="#mantenimientos"
                @click="actualizarItems('mantenimientos')"
              >
                Mantenimientos
              </v-tab>
            -->

            <v-tab
              v-if="mostrarVista(5)"
              key="roles"
              href="#roles"
              @click="actualizarItems('roles')"
            >
              Roles
            </v-tab>

            <v-tab
              v-if="mostrarVista(6)"
              key="usuarios"
              href="#usuarios"
              @click="actualizarItems('usuarios')"
            >
              Usuarios
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item v-if="mostrarVista(1)" value="admision">
              <admision-paciente></admision-paciente>
            </v-tab-item>

            <v-tab-item v-if="mostrarVista(2)" value="consulta_medica">
              <consulta-medica ref="consulta_medica"> </consulta-medica>
            </v-tab-item>

            <v-tab-item v-if="mostrarVista(9)" value="consulta_dental">
              <consulta-dental ref="consulta_dental"> </consulta-dental>
            </v-tab-item>

            <v-tab-item v-if="mostrarVista(10)" value="consulta_nutricional">
              <consulta-nutricional ref="consulta_nutricional">
              </consulta-nutricional>
            </v-tab-item>

            <v-tab-item v-if="mostrarVista(13)" value="promociones">
              <promociones ref="promociones"> </promociones>
            </v-tab-item>

            <v-tab-item v-if="mostrarVista(3)" value="pacientes">
              <pacientes ref="pacientes"></pacientes>
            </v-tab-item>

            <v-tab-item v-if="mostrarVista(11)" value="almacen">
              <almacen ref="almacen"></almacen>
            </v-tab-item>

            <v-tab-item v-if="mostrarVista(7)" value="pagos">
              <pagos></pagos>
            </v-tab-item>

            <v-tab-item v-if="mostrarVista(16)" value="incidencias">
              <incidencias></incidencias>
            </v-tab-item>

            <v-tab-item v-if="mostrarVista(4)" value="catalogos">
              <catalogos></catalogos>
            </v-tab-item>

            <!--
              <v-tab-item
                value="mantenimientos"
              >
                <mantenimientos
                  ref="mantenimientos"
                ></mantenimientos>
              </v-tab-item>
            -->

            <v-tab-item v-if="mostrarVista(5)" value="roles">
              <roles ref="roles"></roles>
            </v-tab-item>

            <v-tab-item v-if="mostrarVista(6)" value="usuarios">
              <users ref="usuarios"></users>
            </v-tab-item>

            <v-tab-item v-if="mostrarVista(17)" value="reportes">
              <reportes ref="reportes"></reportes>
            </v-tab-item>

            <v-tab-item
              v-if="mostrarVista(18) || vinculado"
              value="recursos_humanos"
            >
              <recursos-humanos ref="recursos_humanos"></recursos-humanos>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-row>
      <Footer> </Footer>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Catalogos from './Catalogos.vue'
import Pacientes from './Pacientes.vue'
import Users from './Users.vue'
import Roles from './Roles.vue'
import pagos from './Pagos.vue'
import almacen from './Almacen.vue'
import AdmisionPaciente from './AdmisionPaciente.vue'
import ConsultaMedica from './ConsultaMedica.vue'
import ConsultaDental from './ConsultaDental.vue'
import ConsultaNutricional from './ConsultaNutricional.vue'
import Promociones from './Promociones.vue'
import Incidencias from './Incidencias.vue'
import Reportes from './Reportes.vue'
import RecursosHumanos from './RecursosHumanos.vue'
//import Mantenimientos from './Mantenimientos.vue'
import Footer from '@/components/DashViews/Comunes/Footer.vue'
import { mapState } from 'vuex'

export default {
  components: {
    Catalogos,
    Pacientes,
    Roles,
    Users,
    pagos,
    almacen,
    AdmisionPaciente,
    ConsultaMedica,
    ConsultaDental,
    ConsultaNutricional,
    Promociones,
    Footer,
    Incidencias,
    Reportes,
    RecursosHumanos,
    //Mantenimientos
  },
  computed: {
    ...mapState([
      "ids_vistas",
      "extemporaneo",
      "vinculado",
      "expediente_valido",
    ]),
    vistaGrupos() {
      return this.vista_grupos.filter((x) =>
        this.ids_vistas_grupos.includes(x.id_vista_grupo)
      );
    },
  },
  data: () => ({
    tab: null,
    interval: null,
    loading: false,
    vistas: [],
    vista_grupos: [],
    selector_grupos: false,
    ids_vistas_grupos: [],
    id_vista_grupo_actual: null,
  }),
  mounted() {
    this.getData();
  },
  methods: {
    actualizarItems(refName) {
      if (this.$refs[refName] && this.$refs[refName].getData) {
        this.$refs[refName].getData();
      } else {
        console.warn(`No se encontró la referencia: ${refName}`);
      }
    },
    mostrarVista(id_vista) {
      if (this.ids_vistas == null || this.ids_vistas.length == 0) return false;
      else {
        if (!this.selector_grupos)
          return this.ids_vistas.indexOf(id_vista) >= 0;
        else {
          return (
            this.vistas
              .filter((v) => v.id_vista_grupo == this.id_vista_grupo_actual)
              .map((x) => x.id_vista)
              .indexOf(id_vista) >= 0
          );
        }
      }
    },
    async gotoExpediente() {
      this.tab = "recursos_humanos";

      if (this.interval != null) return;

      this.interval = setInterval(() => {
        if (
          this.$refs["recursos_humanos"] &&
          this.$refs["recursos_humanos"].gotoExpediente
        ) {
          this.$refs["recursos_humanos"].gotoExpediente();
          clearInterval(this.interval);
          this.interval = null;
        }
      }, 100);
    },
    getGrupoVistas() {
      return axios
        .get("/Catalogos/ListarVistaGrupos")
        .then((response) => {
          this.vista_grupos = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getVistas() {
      return axios
        .get("/Catalogos/ListarVistas")
        .then((response) => {
          this.vistas = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getData() {
      this.loading = true; // Inicia el estado de carga

      try {
        // Ejecuta ambas funciones simultáneamente
        await Promise.all([this.getGrupoVistas(), this.getVistas()]);
        // Aquí puedes continuar con el proceso después de que ambas funciones terminen

        this.ids_vistas_grupos = this.vistas
          .filter(
            (vg) => this.ids_vistas.includes(vg.id_vista) && vg.id_vista_grupo
          )
          .reduce((unique, item) => {
            if (!unique.includes(item.id_vista_grupo)) {
              unique.push(item.id_vista_grupo);
            }
            return unique;
          }, []);

        this.selector_grupos = this.ids_vistas_grupos.length > 1;
        this.id_vista_grupo_actual = null;
        this.tab = null;
      } catch (error) {
        console.log("Ocurrió un error:", error);
      } finally {
        this.loading = false; // Finaliza el estado de carga
      }
    },
  },
};
</script>
