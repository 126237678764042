<template>
  <v-container style="max-width: 1800px !important">
    <div class="pa-3 ma-3">
      <v-alert-dialog ref="alert"> </v-alert-dialog>

      <v-dialog
        v-model="dialogAddEdit"
        max-width="80%"
        persistent
      >
        <v-card>
          <v-card-title>
            {{ editedItem.id_empleado == null ? 'Agregar' : 'Editar' }} Empleado
          </v-card-title>
          <v-card-text>
            <v-row class="pa-0 ma-0">
              <v-col
                cols="12"
                class="pa-0 ma-0 text-center"
              >
                <div class="text-subtitle-1 font-weight-bold">
                  <v-icon>
                    mdi-account
                  </v-icon>
                  Datos Personales
                </div>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="editedItem.nombre"
                  label="Nombre"
                  outlined
                  dense
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="editedItem.apellido_paterno"
                  label="Apellido Paterno"
                  outlined
                  dense
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="editedItem.apellido_materno"
                  label="Apellido Materno"
                  outlined
                  dense
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-datetime-picker
                  label="Fecha de Nacimiento"
                  v-model="editedItem.fecha_nacimiento"
                >
                </v-datetime-picker>
              </v-col>
              <v-col cols="4"
                style="align-content: center !important;"
              >
                <v-text-field
                  v-model="editedItem.telefono"
                  label="Teléfono de Contacto"
                  outlined
                  dense
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col cols="4"
                style="align-content: center !important;"
              >
                <v-text-field
                  v-model="editedItem.email"
                  label="Email de Contacto"
                  outlined
                  dense
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  v-model="editedItem.id_estado_civil"
                  label="Estado Civil"
                  outlined
                  dense
                  hide-details
                  :items="estados_civiles"
                  item-value="id_estado_civil"
                  item-text="nombre"
                  :name="uuidv4()"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-if="editedItem.id_empleado == null"
                  v-model="editedItem.curp_valor"
                  label="CURP"
                  outlined
                  dense
                  counter
                  maxlength="18"
                  :rules="curpRules"
                >
                </v-text-field>
                <div v-else class="text-subtitle-1">
                  <div class="text-caption">CURP</div>
                  <div class="font-weight-medium">{{editedItem.curp_valor}}</div>
                </div>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-if="editedItem.id_empleado == null"
                  v-model="editedItem.rfc_valor"
                  label="RFC"
                  outlined
                  dense
                  counter
                  maxlength="13"
                  :rules="rfcRules"
                  @input="buscarUsuario"
                >
                </v-text-field>
                <div v-else class="text-subtitle-1">
                  <div class="text-caption">RFC</div>
                  <div class="font-weight-medium">{{editedItem.rfc_valor}}</div>
                </div>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="editedItem.id_puesto"
                  label="Puesto"
                  outlined
                  dense
                  hide-details
                  item-value="id_puesto"
                  item-text="nombre"
                  :items="puestos"
                >
                </v-autocomplete>
              </v-col>
              <template
                v-if="editedItem.id_empleado == null && editedItem.rfc_valor != null && editedItem.rfc_valor.length == 13"
              >
                <v-col
                  cols="12"
                  class="pa-0 ma-0 text-center"
                >
                  <br>
                  <div class="text-subtitle-1 font-weight-bold">
                    <v-icon>
                      mdi-lock-outline
                    </v-icon>
                    Acceso al Sistema
                  </div>
                  <br>
                </v-col>
                <v-col cols="12" v-if="loadingUser">
                  <div
                    class="text-center"
                    >
                    <v-progress-circular
                      :size="50"
                      color="primary"
                      indeterminate
                    ></v-progress-circular>
                    <br>
                    <br>
                    <div class="text-subtitle'1">Buscando Usuario</div>
                  </div>
                </v-col>
                <v-col cols="12" v-else class="text-body-1 text-center">
                  <template v-if="editedItem.crear_usuario">
                    No se ha detectado ningún usuario en el sistema con el RFC proporcionado. Se creará un usuario nuevo para este empleado.
                  </template>
                  <template v-else>
                    Se ha detectado un usuario a nombre de {{editedItem.nombre_usuario}} en el sistema con el RFC proporcionado. No es necesario crear un usuario para este empleado.
                  </template>
                </v-col>
              </template>
              
              <v-col
                v-if="expedienteRequisitos.length > 0"
                cols="12"
                class="pa-0 ma-0 text-center"
              >
                <br>
                <div class="text-subtitle-1 font-weight-bold">
                  <v-icon>
                    mdi-file-document-outline
                  </v-icon>
                  Documentación
                </div>
                Si cuenta con algún documento de este empleado puede proporcionarlo. Este paso es opcional.
                <br>
              </v-col>
              <template
                v-for="expediente_requisito in expedienteRequisitos"
              >
                <v-col
                  :key="'addEdit_'+expediente_requisito.codigo"
                  cols="6"
                >
                  <template v-if="expediente_requisito.tipo_documento == 1">
                    <!--
                    <v-file-input
                      v-model="editedItem[expediente_requisito.codigo]"
                      truncate-length="100"
                      outlined
                      dense
                      hide-details
                      :label="`${expediente_requisito.nombre}`"
                    ></v-file-input>
                    -->
                    <v-file-input-plus
                      :urlBase="urlBase"
                      dense
                      accept=".jpg,.jpeg,.png,.pdf"
                      :max-size="5242880"
                      outlined
                      :label="`${expediente_requisito.nombre}`"
                      v-model="editedItem[expediente_requisito.codigo]"
                    ></v-file-input-plus>
                    <div v-if="expediente_requisito.nota != null" class="text-caption note-style">Nota: {{expediente_requisito.nota}}</div>
                  </template>
                  <template v-else-if="expediente_requisito.tipo_documento == 2">
                    <v-text-field
                      v-model="editedItem[expediente_requisito.codigo]"
                      outlined
                      dense
                      hide-details
                      :label="`${expediente_requisito.nombre}`"
                    ></v-text-field>
                    <div v-if="expediente_requisito.nota != null" class="text-caption note-style">Nota: {{expediente_requisito.nota}}</div>
                  </template>
                  <template v-else-if="expediente_requisito.tipo_documento == 3">
                    <v-text-field
                      v-model="editedItem[expediente_requisito.codigo]"
                      outlined
                      dense
                      hide-details
                      :label="`${expediente_requisito.nombre}`"
                    ></v-text-field>
                    <div v-if="expediente_requisito.nota != null" class="text-caption note-style">Nota: {{expediente_requisito.nota}}</div>
                  </template>
                  <template v-else-if="expediente_requisito.tipo_documento == 4">
                    <v-text-field
                      v-model="editedItem[expediente_requisito.codigo]"
                      outlined
                      dense
                      hide-details
                      :label="`${expediente_requisito.nombre}`"
                    ></v-text-field>
                    <div v-if="expediente_requisito.nota != null" class="text-caption note-style">Nota: {{expediente_requisito.nota}}</div>
                  </template>
                </v-col>
              </template>

            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green"
              text
              @click="closeDialogAddEdit"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="red"
              text
              @click="saveDialogAddEdit"
              :loading="loadingSave"
              class="white--text"
              >
                Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialogView"
        max-width="80%"
        persistent
      >
        <v-card>
          <v-card-title>
            Detalles del Empleado
          </v-card-title>
          <v-card-text>
            <v-row class="pa-0 ma-0">
              <v-col
                cols="12"
                class="pa-0 ma-0 text-center"
              >
                <div class="text-subtitle-1 font-weight-bold">
                  <v-icon>
                    mdi-account
                  </v-icon>
                  Datos Personales
                </div>
              </v-col>

              <v-col cols="12">
                <v-row class="pa-0 ma-0">
                  <v-col cols="3" class="ma-0 pa-0 text-align-center">
                    <v-avatar
                      size="220"
                    >
                      <v-img
                        :src="(editedItem.imagen_perfil != null) ? `/api/RecursosHumanos/DescargarDocumento/${editedItem.imagen_perfil}?jwt=${token}` : '/img/persona.jpg'"
                      >
                      </v-img>
                    </v-avatar>
                  </v-col>
                  <v-col cols="9" class="ma-0 pa-0">
                    <v-row class="pa-0 ma-0">
                      <v-col cols="4">
                      <div class="text-subtitle-2">Nombre:</div>
                      <div>{{ editedItem.nombre }}</div>
                    </v-col>
                    <v-col cols="4">
                      <div class="text-subtitle-2">Apellido Paterno:</div>
                      <div>{{ editedItem.apellido_paterno }}</div>
                    </v-col>
                    <v-col cols="4">
                      <div class="text-subtitle-2">Apellido Materno:</div>
                      <div>{{ editedItem.apellido_materno }}</div>
                    </v-col>
                    <v-col cols="4">
                      <div class="text-subtitle-2">Fecha de Nacimiento:</div>
                      <div>{{ editedItem.fecha_nacimiento_text }}</div>
                    </v-col>
                    <v-col cols="4">
                      <div class="text-subtitle-2">Teléfono de Contacto:</div>
                      <div>{{ editedItem.telefono }}</div>
                    </v-col>
                    <v-col cols="4">
                      <div class="text-subtitle-2">Email de Contacto:</div>
                      <div>{{ editedItem.email }}</div>
                    </v-col>
                    <v-col cols="4">
                      <div class="text-subtitle-2">Estado Civil:</div>
                      <div>{{ editedItem.nombre_estado_civil }}</div>
                    </v-col>
                    <v-col cols="4">
                      <div class="text-subtitle-2">CURP:</div>
                      <div>{{ editedItem.curp_valor }}</div>
                    </v-col>
                    <v-col cols="4">
                      <div class="text-subtitle-2">RFC:</div>
                      <div>{{ editedItem.rfc_valor }}</div>
                    </v-col>
                    <v-col cols="4">
                      <div class="text-subtitle-2">Puesto:</div>
                      <div>{{ editedItem.nombre_puesto }}</div>
                    </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                v-if="expedienteRequisitos.length > 0"
                cols="12"
                class="pa-0 ma-0 text-center"
              >
                <br>
                <div class="text-subtitle-1 font-weight-bold">
                  <v-icon>
                    mdi-file-document-outline
                  </v-icon>
                  Documentación
                </div>
                <br>
                <v-alert
                  v-if="editedItem.pendiente_carga"
                  dense
                  text
                  dark
                  color="yellow darken-4"
                >
                  Documentación Pendiente de Carga
                </v-alert>
                <v-alert
                  v-else-if="editedItem.expediente_validado"
                  dense
                  text
                  color="green"
                >
                  Documentación Validada por {{editedItem.expediente_validado_por}} desde {{editedItem.expediente_validado_fecha}}
                </v-alert>
                <v-alert
                  v-else-if="!editedItem.expediente_validado"
                  dense
                  text
                  color="red"
                >
                  Documentación Pendiente de Validación
                </v-alert>
              </v-col>
              <template v-if="!editedItem.pendiente_carga">
                <template
                v-for="expediente_requisito in expedienteRequisitosVisualizar"
                >
                  <v-col
                    :key="'view_'+expediente_requisito.codigo"
                    cols="4"
                  >
                    <div class="text-subtitle-2">{{ expediente_requisito.nombre }}:</div>

                    <div v-if="expediente_requisito.tipo_documento == 1">
                      <div v-if="editedItem[expediente_requisito.codigo] != null">
                        <v-viewer
                          v-model="editedItem[expediente_requisito.codigo]"
                          urlBase="/api/RecursosHumanos/DescargarDocumento"
                          :label="expediente_requisito.nombre"
                        ></v-viewer>
                      </div>
                      <div v-else class="text-body-2 text-left">
                        Documento No Proporcionado
                      </div>
                    </div>
                    <div v-if="expediente_requisito.tipo_documento == 2 || expediente_requisito.tipo_documento == 3 || expediente_requisito.tipo_documento == 4">
                      {{editedItem[expediente_requisito.codigo]}}
                    </div>
                  </v-col>
                </template>
              </template>
              
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green"
              text
              @click="closeDialogView"
            >
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialogValidate"
        max-width="80%"
        persistent
      >
        <v-card>
          <v-card-title>
            Validación de Documentación
          </v-card-title>
          <v-card-text>
            <v-row class="pa-0 ma-0">
              <v-col
                cols="12"
                class="pa-0 ma-0 text-center"
              >
                <div class="text-subtitle-1 font-weight-bold">
                  <v-icon>
                    mdi-account
                  </v-icon>
                  Datos Personales
                </div>
              </v-col>

              <v-col cols="12">
                <v-row class="pa-0 ma-0">
                  <v-col cols="3" class="ma-0 pa-0 text-align-center">
                    <v-avatar
                      size="220"
                    >
                      <v-img
                        :src="(validatedItem.imagen_perfil != null) ? `/api/RecursosHumanos/DescargarDocumento/${validatedItem.imagen_perfil}?jwt=${token}` : '/img/persona.jpg'"
                      >
                      </v-img>
                    </v-avatar>
                  </v-col>
                  <v-col cols="9" class="ma-0 pa-0">
                    <v-row class="pa-0 ma-0">
                      <v-col cols="4">
                      <div class="text-subtitle-2">Nombre:</div>
                      <div>{{ validatedItem.nombre }}</div>
                    </v-col>
                    <v-col cols="4">
                      <div class="text-subtitle-2">Apellido Paterno:</div>
                      <div>{{ validatedItem.apellido_paterno }}</div>
                    </v-col>
                    <v-col cols="4">
                      <div class="text-subtitle-2">Apellido Materno:</div>
                      <div>{{ validatedItem.apellido_materno }}</div>
                    </v-col>
                    <v-col cols="4">
                      <div class="text-subtitle-2">Fecha de Nacimiento:</div>
                      <div>{{ validatedItem.fecha_nacimiento }}</div>
                    </v-col>
                    <v-col cols="4">
                      <div class="text-subtitle-2">Teléfono de Contacto:</div>
                      <div>{{ validatedItem.telefono }}</div>
                    </v-col>
                    <v-col cols="4">
                      <div class="text-subtitle-2">Email de Contacto:</div>
                      <div>{{ validatedItem.email }}</div>
                    </v-col>
                    <v-col cols="4">
                      <div class="text-subtitle-2">Estado Civil:</div>
                      <div>{{ validatedItem.nombre_estado_civil }}</div>
                    </v-col>
                    <v-col cols="4">
                      <div class="text-subtitle-2">CURP:</div>
                      <div>{{ validatedItem.curp_valor }}</div>
                    </v-col>
                    <v-col cols="4">
                      <div class="text-subtitle-2">RFC:</div>
                      <div>{{ validatedItem.rfc_valor }}</div>
                    </v-col>
                    <v-col cols="4">
                      <div class="text-subtitle-2">Puesto:</div>
                      <div>{{ validatedItem.nombre_puesto }}</div>
                    </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>

              <template
                v-for="expediente_requisito in expedienteRequisitosValidar"
              >
                <v-col
                  :key="'validate_'+expediente_requisito.codigo"
                  cols="4"
                >
                  <div class="text-subtitle-2">{{ expediente_requisito.nombre }}:</div>
                  
                  <div v-if="expediente_requisito.tipo_documento == 1">
                    <template v-if="validatedItem[expediente_requisito.codigo] != null">
                      <VFileInputError
                        downloadUrl="/api/RecursosHumanos/DescargarDocumento"
                        :token="token"
                        accept=".jpg,.jpeg,.png,.pdf"
                        :label="expediente_requisito.nombre"
                        :value="validatedItem[expediente_requisito.codigo]"
                        :error-state="validatedItem[`error_${expediente_requisito.codigo}`]"
                        @update:value="updateField(expediente_requisito.codigo, $event)"
                        @update:errorState="updateField(`error_${expediente_requisito.codigo}`, $event)"
                        @update:textareaModel="updateField(`msg_${expediente_requisito.codigo}`, $event)"
                      />
                    </template>
                    <template v-else>
                      Documento No Proporcionado
                    </template>
                  </div>
                  
                  <div v-if="expediente_requisito.tipo_documento == 2 || expediente_requisito.tipo_documento == 3 || expediente_requisito.tipo_documento == 4">
                    <v-row class="pa-0 ma-0">
                      <v-col cols="3" class="pa-0 ma-0">
                        <v-check
                          v-model="validatedItem[`error_${expediente_requisito.codigo}`]"
                          class="pa-0 ma-0"
                          dense
                          hide-details
                          indeterminate
                        >
                        </v-check>
                      </v-col>
                      <v-col cols="9" class="pa-0 ma-0 align-self-center">
                          {{validatedItem[expediente_requisito.codigo]}}
                      </v-col>
                      <v-col 
                        v-if="validatedItem[`error_${expediente_requisito.codigo}`] == false"
                        cols="12"
                        class="ma-0 pa-0 pt-3"
                      >
                        <v-textarea
                          v-model="validatedItem[`msg_${expediente_requisito.codigo}`]"
                          outlined
                          dense
                          hide-details
                          rows="1"
                          auto-grow
                          :label="`Anomalia - ${expediente_requisito.nombre}`"
                        >
                        </v-textarea>
                      </v-col>
                    </v-row>
                  </div>
                  
                </v-col>
              </template>
              
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green"
              text
              @click="closeDialogValidate"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="red"
              text
              @click="saveDialogValidation"
              :loading="loadingSave"
              class="white--text"
              >
                Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <v-row>
        <v-col cols="12">
          <v-btn color="primary" @click="openDialogAddEdit(null)">
            Agregar Empleado
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table
            :loading="loading"
            :headers="headers"
            :items="items"
          >
            <template v-slot:[`item.fotografia`]="{ item }">
              <v-avatar class="my-2">
                <v-img :src="(item.imagen_perfil != null) ? `/api/RecursosHumanos/DescargarDocumento/${item.imagen_perfil}?jwt=${token}` : '/img/persona.jpg'"></v-img>
              </v-avatar>
            </template>
            <template v-slot:[`item.expediente_validado`]="{ item }">
              <VTooltipIconV2
                v-if="item.pendiente_carga"
                color="gray"
                icon="mdi-timer-sand"
                :tooltip-text="`Pendiente Carga de Documentación`"
              >
              </VTooltipIconV2>

              <VTooltipIconV2
                v-else-if="!item.expediente_validado"
                color="red"
                icon="mdi-alert"
                :tooltip-text="`En Espera de Validación de Documentacion`"
              >
              </VTooltipIconV2>

              <VTooltipIconV2
                v-else
                color="green"
                icon="mdi-check"
                :tooltip-text="`Documentación Validada por ${item.expediente_validado_por} desde ${item.expediente_validado_fecha}`"
              >
              </VTooltipIconV2>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                class="mr-2"
                small
                @click="openDialogAddEdit(item)"
              >
                mdi-pencil
              </v-icon>

              <v-icon
                class="mr-2"
                small
                @click="openDialogView(item)"
              >
                mdi-eye
              </v-icon>

              <v-icon
                v-if="!item.pendiente_carga && !item.expediente_validado"
                class="mr-2"
                small
                @click="openDialogValidate(item)"
              >
                mdi-check-bold
              </v-icon>
           </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from 'axios'
import { mapState } from "vuex";
import VAlertDialog from "@/components/VAlertDialog.vue";
import VDatetimePicker from '@/components/VDatetimePicker.vue'
import VTooltipIconV2 from '@/components/VTooltipIconV2.vue';
import VCheck from '@/components/VCheck.vue';
import VFileInputPlus from '@/components/VFileInputPlus.vue';
import VViewer from '@/components/VViewer.vue';
import VFileInputError from './VFileInputError.vue';
import { v4 } from 'uuid';

export default {
  components: {
    VAlertDialog,
    VDatetimePicker,
    VTooltipIconV2,
    VCheck,
    VFileInputPlus,
    VViewer,
    VFileInputError
  },
  data: () => ({
    dialogView: false,
    dialogAddEdit: false,
    loading: false,
    items: [],
    headers: [
      {
        text: "Fotografia",
        value: "fotografia",
        align: "center",
      },
      {
        text: "# Empleado",
        value: "codigo_empleado",
        align: "center",
      },
      {
        text: "Nombre",
        value: "nombre",
        align: "center",
      },
      {
        text: "Apellido Paterno",
        value: "apellido_paterno",
        align: "center",
      },
      {
        text: "Apellido Materno",
        value: "apellido_materno",
        align: "center",
      },
      {
        text: "Puesto",
        value: "nombre_puesto",
        align: "center",
      },
      {
        text: "RFC",
        value: "rfc_valor",
        align: "center",
      },
      {
        text: "Expediente",
        value: "expediente_validado",
        align: "center",
      },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        align: "center",
      },
    ],
    curpRules: [v => (v && v.length === 18) || 'La longitud de la CURP debe de ser de 18 caracteres'],
    rfcRules: [v => (v && v.length === 13) || 'La longitud del RFC debe de ser de 13 caracteres'],
    editedItem: {
      id_empleado: null,
      id_puesto: null,
      codigo_empleado: null,
      nombre_puesto: null,
      nombre: null,
      apellido_paterno: null,
      apellido_materno: null,
      fecha_nacimiento: null,
      telefono: null,
      email: null,
      id_estado_civil: null,
      nombre_estado_civil: null,
      curp_valor: null,
      rfc_valor: null,
      expediente_validado: false,
      expediente_validado_fecha: null,
      expediente_validado_por: null,
      imagen_perfil: null,
      id_usuario: null,
      crear_usuario: false,
      nombre_usuario: null,

      acta_nacimiento: null,
      caratula_banco: null,
      carta_antecedentes_no_penales: null,
      carta_recomendacion_1: null,
      carta_recomendacion_2: null,
      cedula_profesional: null,
      cedula_profesional_kardex: null,
      cedula_profesional_kardex_salud: null,
      certificado_covid: null,
      certificado_estudios_cedula_profesional_titulo: null,
      certificado_estudios_kardex_legalizado: null,
      certificado_medico: null,
      certificado_ultimo_grado: null,
      comprobante_domicilio: null,
      constancia_situacion_fiscal: null,
      curp: null,
      curriculum_vitae: null,
      fotografia_credencial: null,
      identificacion_oficial: null,
      kardex_legalizado: null,
      licencia_conducir: null,
      licencia_conducir_equipo_pesado: null,
      nombre_contacto_emergencia: null,
      nss_imss: null,
      solicitud_empleo: null,
      telefono_contacto_emergencia: null,
      titulo_cedula_posgrado: null,
      titulo_profesional: null,
      titulo_profesional_carta_pasante: null,
    },
    defaultEditedItem: {
      id_empleado: null,
      id_puesto: null,
      codigo_empleado: null,
      nombre_puesto: null,
      nombre: null,
      apellido_paterno: null,
      apellido_materno: null,
      fecha_nacimiento: null,
      telefono: null,
      email: null,
      id_estado_civil: null,
      nombre_estado_civil: null,
      curp_valor: null,
      rfc_valor: null,
      expediente_validado: false,
      expediente_validado_fecha: null,
      expediente_validado_por: null,
      imagen_perfil: null,
      id_usuario: null,
      crear_usuario: false,
      nombre_usuario: null,

      acta_nacimiento: null,
      caratula_banco: null,
      carta_antecedentes_no_penales: null,
      carta_recomendacion_1: null,
      carta_recomendacion_2: null,
      cedula_profesional: null,
      cedula_profesional_kardex: null,
      cedula_profesional_kardex_salud: null,
      certificado_covid: null,
      certificado_estudios_cedula_profesional_titulo: null,
      certificado_estudios_kardex_legalizado: null,
      certificado_medico: null,
      certificado_ultimo_grado: null,
      comprobante_domicilio: null,
      constancia_situacion_fiscal: null,
      curp: null,
      curriculum_vitae: null,
      fotografia_credencial: null,
      identificacion_oficial: null,
      kardex_legalizado: null,
      licencia_conducir: null,
      licencia_conducir_equipo_pesado: null,
      nombre_contacto_emergencia: null,
      nss_imss: null,
      solicitud_empleo: null,
      telefono_contacto_emergencia: null,
      titulo_cedula_posgrado: null,
      titulo_profesional: null,
      titulo_profesional_carta_pasante: null,
    },
    validatedItem: {
      id_empleado: null,
      id_puesto: null,
      codigo_empleado: null,
      nombre_puesto: null,
      nombre: null,
      apellido_paterno: null,
      apellido_materno: null,
      fecha_nacimiento: null,
      telefono: null,
      email: null,
      id_estado_civil: null,
      nombre_estado_civil: null,
      curp_valor: null,
      rfc_valor: null,
      expediente_validado: false,
      expediente_validado_fecha: null,
      expediente_validado_por: null,
      imagen_perfil: null,
      id_usuario: null,
      crear_usuario: false,
      nombre_usuario: null,

      acta_nacimiento: null,
      caratula_banco: null,
      carta_antecedentes_no_penales: null,
      carta_recomendacion_1: null,
      carta_recomendacion_2: null,
      cedula_profesional: null,
      cedula_profesional_kardex: null,
      cedula_profesional_kardex_salud: null,
      certificado_covid: null,
      certificado_estudios_cedula_profesional_titulo: null,
      certificado_estudios_kardex_legalizado: null,
      certificado_medico: null,
      certificado_ultimo_grado: null,
      comprobante_domicilio: null,
      constancia_situacion_fiscal: null,
      curp: null,
      curriculum_vitae: null,
      fotografia_credencial: null,
      identificacion_oficial: null,
      kardex_legalizado: null,
      licencia_conducir: null,
      licencia_conducir_equipo_pesado: null,
      nombre_contacto_emergencia: null,
      nss_imss: null,
      solicitud_empleo: null,
      telefono_contacto_emergencia: null,
      titulo_cedula_posgrado: null,
      titulo_profesional: null,
      titulo_profesional_carta_pasante: null,

      msg_acta_nacimiento: null,
      msg_caratula_banco: null,
      msg_carta_antecedentes_no_penales: null,
      msg_carta_recomendacion_1: null,
      msg_carta_recomendacion_2: null,
      msg_cedula_profesional: null,
      msg_cedula_profesional_kardex: null,
      msg_cedula_profesional_kardex_salud: null,
      msg_certificado_covid: null,
      msg_certificado_estudios_cedula_profesional_titulo: null,
      msg_certificado_estudios_kardex_legalizado: null,
      msg_certificado_medico: null,
      msg_certificado_ultimo_grado: null,
      msg_comprobante_domicilio: null,
      msg_constancia_situacion_fiscal: null,
      msg_curp: null,
      msg_curriculum_vitae: null,
      msg_fotografia_credencial: null,
      msg_identificacion_oficial: null,
      msg_kardex_legalizado: null,
      msg_licencia_conducir: null,
      msg_licencia_conducir_equipo_pesado: null,
      msg_nombre_contacto_emergencia: null,
      msg_nss_imss: null,
      msg_solicitud_empleo: null,
      msg_telefono_contacto_emergencia: null,
      msg_titulo_cedula_posgrado: null,
      msg_titulo_profesional: null,
      msg_titulo_profesional_carta_pasante: null,

      error_acta_nacimiento: null,
      error_caratula_banco: null,
      error_carta_antecedentes_no_penales: null,
      error_carta_recomendacion_1: null,
      error_carta_recomendacion_2: null,
      error_cedula_profesional: null,
      error_cedula_profesional_kardex: null,
      error_cedula_profesional_kardex_salud: null,
      error_certificado_covid: null,
      error_certificado_estudios_cedula_profesional_titulo: null,
      error_certificado_estudios_kardex_legalizado: null,
      error_certificado_medico: null,
      error_certificado_ultimo_grado: null,
      error_comprobante_domicilio: null,
      error_constancia_situacion_fiscal: null,
      error_curp: null,
      error_curriculum_vitae: null,
      error_fotografia_credencial: null,
      error_identificacion_oficial: null,
      error_kardex_legalizado: null,
      error_licencia_conducir: null,
      error_licencia_conducir_equipo_pesado: null,
      error_nombre_contacto_emergencia: null,
      error_nss_imss: null,
      error_solicitud_empleo: null,
      error_telefono_contacto_emergencia: null,
      error_titulo_cedula_posgrado: null,
      error_titulo_profesional: null,
      error_titulo_profesional_carta_pasante: null,
    },
    defaultValidatedItem: {
      id_empleado: null,
      id_puesto: null,
      codigo_empleado: null,
      nombre_puesto: null,
      nombre: null,
      apellido_paterno: null,
      apellido_materno: null,
      fecha_nacimiento: null,
      telefono: null,
      email: null,
      id_estado_civil: null,
      nombre_estado_civil: null,
      curp_valor: null,
      rfc_valor: null,
      expediente_validado: false,
      expediente_validado_fecha: null,
      expediente_validado_por: null,
      imagen_perfil: null,
      id_usuario: null,
      crear_usuario: false,
      nombre_usuario: null,

      acta_nacimiento: null,
      caratula_banco: null,
      carta_antecedentes_no_penales: null,
      carta_recomendacion_1: null,
      carta_recomendacion_2: null,
      cedula_profesional: null,
      cedula_profesional_kardex: null,
      cedula_profesional_kardex_salud: null,
      certificado_covid: null,
      certificado_estudios_cedula_profesional_titulo: null,
      certificado_estudios_kardex_legalizado: null,
      certificado_medico: null,
      certificado_ultimo_grado: null,
      comprobante_domicilio: null,
      constancia_situacion_fiscal: null,
      curp: null,
      curriculum_vitae: null,
      fotografia_credencial: null,
      identificacion_oficial: null,
      kardex_legalizado: null,
      licencia_conducir: null,
      licencia_conducir_equipo_pesado: null,
      nombre_contacto_emergencia: null,
      nss_imss: null,
      solicitud_empleo: null,
      telefono_contacto_emergencia: null,
      titulo_cedula_posgrado: null,
      titulo_profesional: null,
      titulo_profesional_carta_pasante: null,

      msg_acta_nacimiento: null,
      msg_caratula_banco: null,
      msg_carta_antecedentes_no_penales: null,
      msg_carta_recomendacion_1: null,
      msg_carta_recomendacion_2: null,
      msg_cedula_profesional: null,
      msg_cedula_profesional_kardex: null,
      msg_cedula_profesional_kardex_salud: null,
      msg_certificado_covid: null,
      msg_certificado_estudios_cedula_profesional_titulo: null,
      msg_certificado_estudios_kardex_legalizado: null,
      msg_certificado_medico: null,
      msg_certificado_ultimo_grado: null,
      msg_comprobante_domicilio: null,
      msg_constancia_situacion_fiscal: null,
      msg_curp: null,
      msg_curriculum_vitae: null,
      msg_fotografia_credencial: null,
      msg_identificacion_oficial: null,
      msg_kardex_legalizado: null,
      msg_licencia_conducir: null,
      msg_licencia_conducir_equipo_pesado: null,
      msg_nombre_contacto_emergencia: null,
      msg_nss_imss: null,
      msg_solicitud_empleo: null,
      msg_telefono_contacto_emergencia: null,
      msg_titulo_cedula_posgrado: null,
      msg_titulo_profesional: null,
      msg_titulo_profesional_carta_pasante: null,

      error_acta_nacimiento: null,
      error_caratula_banco: null,
      error_carta_antecedentes_no_penales: null,
      error_carta_recomendacion_1: null,
      error_carta_recomendacion_2: null,
      error_cedula_profesional: null,
      error_cedula_profesional_kardex: null,
      error_cedula_profesional_kardex_salud: null,
      error_certificado_covid: null,
      error_certificado_estudios_cedula_profesional_titulo: null,
      error_certificado_estudios_kardex_legalizado: null,
      error_certificado_medico: null,
      error_certificado_ultimo_grado: null,
      error_comprobante_domicilio: null,
      error_constancia_situacion_fiscal: null,
      error_curp: null,
      error_curriculum_vitae: null,
      error_fotografia_credencial: null,
      error_identificacion_oficial: null,
      error_kardex_legalizado: null,
      error_licencia_conducir: null,
      error_licencia_conducir_equipo_pesado: null,
      error_nombre_contacto_emergencia: null,
      error_nss_imss: null,
      error_solicitud_empleo: null,
      error_telefono_contacto_emergencia: null,
      error_titulo_cedula_posgrado: null,
      error_titulo_profesional: null,
      error_titulo_profesional_carta_pasante: null,
    },
    loadingSave: false,
    urlBase: "/api/RecursosHumanos/DescargarDocumento",
    estados_civiles: [
    ],
    loadingEstadosCiviles: false,
    loadingUser: false,

    puestos: [],
    loadingPuestos: false,
    
    dialogValidate: false,
  }),
  mounted() {
    this.getItems();
    this.getPuestos();
    this.getEstadosCiviles();
  },
  methods: {
    uuidv4(){
      return v4();
    },

    getPuestos(){
      this.loadingPuestos = true;
      axios
      .get('/RecursosHumanos/ListarPuestos')
      .then(response => {
        this.puestos = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingPuestos = false;
      })
    },
    getEstadosCiviles(){
      this.loadingEstadosCiviles = true;
      axios
      .get('/Catalogos/ListarEstadosCiviles')
      .then(response => {
        this.estados_civiles = response.data.filter(x => x.estandar == true);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingEstadosCiviles = false;
      })
    },
    getItems() {
      this.loading = true;
      axios
      .get('/RecursosHumanos/ListarEmpleados')
      .then(response => {
        this.items = response.data;
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      })
    },
    openDialogAddEdit(editItem = null) {
      if(editItem != null) this.editedItem = Object.assign({}, editItem);
      else this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogAddEdit = true;
    },
    closeDialogAddEdit() {
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogAddEdit = false;
    },
    validEmployee(){
      if(this.editedItem.nombre == null || this.editedItem.nombre.length <= 0){
        this.$refs.alert.setMessage("", "Debe de proporcionar un Nombre");
        this.$refs.alert.open();
        return false;
      }
      
      if(this.editedItem.apellido_paterno == null || this.editedItem.apellido_paterno.length <= 0){
        this.$refs.alert.setMessage("", "Debe de proporcionar un Apellido Paterno");
        this.$refs.alert.open();
        return false;
      }
      
      if(this.editedItem.fecha_nacimiento == null || this.editedItem.fecha_nacimiento.length <= 0){
        this.$refs.alert.setMessage("", "Debe de indicar una Fecha de Nacimiento");
        this.$refs.alert.open();
        return false;
      }
      if(this.editedItem.telefono == null || this.editedItem.telefono.length <= 0){
        this.$refs.alert.setMessage("", "Debe de proporcionar un Teléfono de Contacto");
        this.$refs.alert.open();
        return false;
      }
      if(this.editedItem.email == null || this.editedItem.email.length <= 0){
        this.$refs.alert.setMessage("", "Debe de proporcionar un Email de Contacto");
        this.$refs.alert.open();
        return false;
      }
      if(this.editedItem.id_estado_civil == null){
        this.$refs.alert.setMessage("", "Debe de seleccionar un Estado Civil");
        this.$refs.alert.open();
        return false;
      }
      if(this.editedItem.curp_valor == null || this.editedItem.curp_valor.length != 18){
        this.$refs.alert.setMessage("", "Debe de proporcionar una CURP Valida");
        this.$refs.alert.open();
        return false;
      }
      if(this.editedItem.rfc_valor == null || this.editedItem.rfc_valor.length != 13){
        this.$refs.alert.setMessage("", "Debe de proporcionar un RFC Valido");
        this.$refs.alert.open();
        return false;
      }
      if(this.editedItem.id_puesto == null){
        this.$refs.alert.setMessage("", "Debe de seleccionar un Puesto");
        this.$refs.alert.open();
        return false;
      }

      return true;
    },
    saveDialogAddEdit() {
      if (!this.validEmployee()) return;

      this.loadingSave = true;

      // Convierte los campos de `this.editedItem` en entradas de `FormData`
      const formData = new FormData();
      Object.keys(this.editedItem).forEach(key => {
        if(this.editedItem[key] != null) formData.append(key, this.editedItem[key]);
      });

      axios({
        method: this.editedItem.id_empleado == null ? 'POST' : 'PUT',
        url: this.editedItem.id_empleado == null ? '/RecursosHumanos/CrearEmpleado' : `/RecursosHumanos/EditarEmpleado`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' } // Asegura el tipo de contenido
      })
      .then(() => {
        this.getItems();
        this.closeDialogAddEdit();
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingSave = false;
      });
    },
    downloadFile(fichero){
      var url = `/api/RecursosHumanos/DescargarDocumento/${fichero}?jwt=${this.$store.state.token}`;
      window.open(url, '_blank');
    },
    getRowClass(item) {
      return (item.motivo_bloqueo != null && item.motivo_bloqueo.length > 0) ? 'red-row' : '';
    },
    buscarUsuario(){
      if(this.editedItem.rfc_valor == null || this.editedItem.rfc_valor.length != 13) return;

      this.loadingUser = true;

      axios
      .get(`/Users/GetUserUnlinked/${this.editedItem.rfc_valor}`)
      .then(response => {
        if(response.data.id_usuario != null) {
          this.editedItem.id_usuario = response.data.id_usuario;
          this.editedItem.nombre_usuario = response.data.nombre_completo;
          this.editedItem.crear_usuario = false;
        }
        else {
          this.editedItem.id_usuario = null;
          this.editedItem.nombre_usuario = null;
          this.editedItem.crear_usuario = true;
        }
      })
      .catch(() => {
        this.editedItem.id_usuario = null;
        this.editedItem.nombre_usuario = null;
        this.editedItem.crear_usuario = true;
      })
      .finally(() => {
        this.loadingUser = false
      })
    },
    openDialogView(editItem) {
      this.editedItem = Object.assign({}, editItem);
      this.dialogView = true;
    },
    closeDialogView() {
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogView = false;
    },
    openDialogValidate(editItem) {
      var reference = Object.assign({}, this.defaultValidatedItem)
      this.validatedItem = Object.assign(reference, editItem);
      this.dialogValidate = true;
    },
    closeDialogValidate() {
      this.validatedItem = Object.assign({}, this.defaultValidatedItem);
      this.dialogValidate = false;
    },
    validateValidation(){
      for (let i = 0; i <= this.expedienteRequisitosValidar.length; i++) {
        var requisito = this.expedienteRequisitosValidar[i];
        if((requisito.requerido == true && this.validatedItem[`error_${requisito.codigo}`] == null) || (requisito.requerido == false && this.validatedItem[requisito.codigo] != null && this.validatedItem[`error_${requisito.codigo}`] == null)){
          this.$refs.alert.setMessage("", `Debe de indicar si ${requisito.nombre.replace(' *', '')} es Valido o No.`);
          this.$refs.alert.open();
          return false;
        }

        if(this.validatedItem[`error_${requisito.codigo}`] == false && (this.validatedItem[`msg_${requisito.codigo}`] == null || this.validatedItem[`msg_${requisito.codigo}`].length <= 3)){
          this.$refs.alert.setMessage("", `Debe de indicar el motivo por el cual no es valido ${requisito.nombre.replace(' *', '')}`);
          this.$refs.alert.open();
          return false;
        }
      }
      return true;
    },
    saveDialogValidation(){
      if (!this.validateValidation()) return;

      this.loadingSave = true;

      // Convierte los campos de `this.editedItem` en entradas de `FormData`
      const formData = new FormData();
      Object.keys(this.validatedItem).forEach(key => {
        if(this.validatedItem[key] != null) formData.append(key, this.validatedItem[key]);
      });

      axios({
        method: 'PUT',
        url: `/RecursosHumanos/ValidarEmpleado`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' } // Asegura el tipo de contenido
      })
      .then(() => {
        this.getItems();
        this.closeDialogValidate();
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.loadingSave = false;
      });
    },
    updateField(field, value) {
      //this.$set(this.editedItem, field, value);
      this.validatedItem[field] = value;
    },
  },
  computed: {
    ...mapState(['token']),
    expedienteRequisitos(){
      if(typeof this.editedItem.id_puesto == 'undefined'  || this.editedItem.id_puesto == null) return [];
      var idx = this.puestos.findIndex(x => x.id_puesto == this.editedItem.id_puesto);
      return (idx >= 0) ? this.puestos[idx].expediente_requisitos : [];
    },
    expedienteRequisitosVisualizar(){
      if(typeof this.editedItem.id_puesto == 'undefined'  || this.editedItem.id_puesto == null) return [];
      var idx = this.puestos.findIndex(x => x.id_puesto == this.editedItem.id_puesto);
      return (idx >= 0) ? this.puestos[idx].expediente_requisitos.filter(x => x.id_expediente_requisito != '58e817f0-9fd2-444b-975f-981f128c2366') : [];
    },
    expedienteRequisitosValidar(){
      if(typeof this.validatedItem.id_puesto == 'undefined'  || this.validatedItem.id_puesto == null) return [];
      var idx = this.puestos.findIndex(x => x.id_puesto == this.validatedItem.id_puesto);
      return (idx >= 0) ? this.puestos[idx].expediente_requisitos.filter(x => x.id_expediente_requisito != '58e817f0-9fd2-444b-975f-981f128c2366') : [];
    }
  }
};
</script>


<style scoped>
.red-row {
  background-color: red !important;
  color: white;
}
</style>