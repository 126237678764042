// https://vuex.vuejs.org/en/state.html

export default {
  authStatus: '',
  token: localStorage.getItem('token') || '',
  user: localStorage.getItem('user') || '',
  ids_roles: JSON.parse(localStorage.getItem('ids_roles')  || '[]'),
  ids_vistas: JSON.parse(localStorage.getItem('ids_vistas') || '[]'),
  ids_unidades_medicas: JSON.parse(localStorage.getItem('ids_unidades_medicas')  || '[]'),
  id_unidad_medica_activa: localStorage.getItem('id_unidad_medica_activa'),
  nombre: localStorage.getItem('nombre') || '',
  apellido_paterno: localStorage.getItem('apellido_paterno') || '',
  apellido_materno: localStorage.getItem('apellido_materno') || '',
  es_medico: JSON.parse(localStorage.getItem('es_medico')  || 'false'),
  configuracion_doctor: JSON.parse(localStorage.getItem('configuracion_doctor')  || 'false'),
  es_admin: JSON.parse(localStorage.getItem('es_admin')  || 'false'),
  extemporaneo: JSON.parse(localStorage.getItem('extemporaneo')  || 'false'),
  vinculado: JSON.parse(localStorage.getItem('vinculado')  || 'true'),
  expediente_valido: JSON.parse(localStorage.getItem('expediente_valido')  || 'true'),
}