// https://vuex.vuejs.org/en/actions.html
import axios from 'axios'
import router from '@/router'

// The login action passes vuex commit helper that we will use to trigger mutations.
export default {
  extemporaneo ({ commit }, { extemporaneo }) {
    return new Promise((resolve) => {
      localStorage.setItem('extemporaneo', extemporaneo);
      commit('extemporaneo', { extemporaneo })
      resolve()
    })
  },
  login ({ commit }, userData) {
    return new Promise((resolve, reject) => {
      commit('auth_request')
      axios.post('/Users/Login', { username: userData.username, password: userData.password })
        .then(response => {
          if(typeof response.data.token !== 'undefined'){
            const token = response.data.token
            const ids_roles = response.data.ids_roles
            const ids_vistas = response.data.ids_vistas
            const ids_unidades_medicas = response.data.ids_unidades_medicas
            const id_unidad_medica_activa = response.data.id_unidad_medica_activa
            const nombre = response.data.nombre
            const apellido_paterno = response.data.apellido_paterno
            const apellido_materno = response.data.apellido_materno
            const es_medico = response.data.es_medico
            const configuracion_doctor = response.data.configuracion_doctor
            const es_admin = response.data.es_admin
            const vinculado = response.data.vinculado
            const expediente_validado = response.data.expediente_validado

            // obtain claims
            var ca = token
            var base64Url = ca.split('.')[1];
            var payload = JSON.parse(window.atob(base64Url));
            const user = payload.Nombre;
            
            // storing jwt in localStorage. https cookie is safer place to store
            localStorage.setItem('token', token);
            localStorage.setItem('user', user);
            localStorage.setItem('ids_roles', JSON.stringify(ids_roles))
            localStorage.setItem('ids_vistas', JSON.stringify(ids_vistas))
            localStorage.setItem('ids_unidades_medicas', JSON.stringify(ids_unidades_medicas))
            localStorage.setItem('id_unidad_medica_activa', id_unidad_medica_activa)
            localStorage.setItem('nombre', nombre)
            localStorage.setItem('apellido_paterno', apellido_paterno)
            localStorage.setItem('apellido_materno', apellido_materno)
            localStorage.setItem('es_medico', JSON.stringify(es_medico))
            localStorage.setItem('configuracion_doctor', JSON.stringify(configuracion_doctor))
            localStorage.setItem('es_admin', JSON.stringify(es_admin))
            localStorage.setItem('vinculado', JSON.stringify(vinculado))
            localStorage.setItem('expediente_validado', JSON.stringify(expediente_validado))

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
            
            // mutation to change state properties to the values passed along
            commit('auth_success', { token, user, ids_roles, ids_vistas, ids_unidades_medicas, id_unidad_medica_activa, nombre, apellido_paterno, apellido_materno, es_medico, configuracion_doctor, es_admin, vinculado, expediente_validado })
            resolve('auth_success')
          }
          if(typeof response.data.msg !== 'undefined' && response.data.msg == 'DF'){
            commit('double_factor_required')
            resolve('double_factor_required')
          }
        })
        .catch(err => {
          console.log('login error')
          commit('auth_error')
          localStorage.removeItem('token')
          localStorage.removeItem('user')
          localStorage.removeItem('ids_roles')
          localStorage.removeItem('ids_vistas')
          localStorage.removeItem('ids_unidades_medicas')
          localStorage.removeItem('id_unidad_medica_activa')
          localStorage.removeItem('nombre')
          localStorage.removeItem('apellido_paterno')
          localStorage.removeItem('apellido_materno')
          localStorage.removeItem('es_medico')
          localStorage.removeItem('configuracion_doctor')
          localStorage.removeItem('es_admin')
          localStorage.removeItem('vinculado')
          localStorage.removeItem('expediente_validado')
          reject(err)
        })
    })
  },
  logout ({ commit }) {
    return new Promise((resolve) => {
      commit('logout');
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      localStorage.removeItem('ids_roles')
      localStorage.removeItem('ids_vistas')
      localStorage.removeItem('ids_unidades_medicas')
      localStorage.removeItem('id_unidad_medica_activa')
      localStorage.removeItem('nombre')
      localStorage.removeItem('apellido_paterno')
      localStorage.removeItem('apellido_materno')
      localStorage.removeItem('es_medico')
      localStorage.removeItem('configuracion_doctor')
      localStorage.removeItem('es_admin')
      localStorage.removeItem('vinculado')
      localStorage.removeItem('expediente_validado')
      delete axios.defaults.headers.common['Authorization']
      resolve()
    })
  },
  refreshtoken ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('/Users/Refresh')
      .then(response => {
        const token = response.data.token
        const ids_roles = response.data.ids_roles
        const ids_vistas = response.data.ids_vistas
        const ids_unidades_medicas = response.data.ids_unidades_medicas
        const id_unidad_medica_activa = response.data.id_unidad_medica_activa
        const nombre = response.data.nombre
        const apellido_paterno = response.data.apellido_paterno
        const apellido_materno = response.data.apellido_materno
        const es_medico = response.data.es_medico
        const configuracion_doctor = response.data.configuracion_doctor
        const es_admin = response.data.es_admin
        const vinculado = response.data.vinculado
        const expediente_validado = response.data.expediente_validado

        localStorage.setItem('token', token)
        localStorage.setItem('ids_roles', JSON.stringify(ids_roles))
        localStorage.setItem('ids_vistas', JSON.stringify(ids_vistas))
        localStorage.setItem('ids_unidades_medicas', JSON.stringify(ids_unidades_medicas))
        localStorage.setItem('id_unidad_medica_activa', id_unidad_medica_activa)
        localStorage.setItem('nombre', nombre)
        localStorage.setItem('apellido_paterno', apellido_paterno)
        localStorage.setItem('apellido_materno', apellido_materno)
        localStorage.setItem('es_medico', JSON.stringify(es_medico))
        localStorage.setItem('configuracion_doctor', JSON.stringify(configuracion_doctor))
        localStorage.setItem('es_admin', JSON.stringify(es_admin))
        localStorage.setItem('vinculado', JSON.stringify(vinculado))
        localStorage.setItem('expediente_validado', JSON.stringify(expediente_validado))

        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
        commit('auth_refresh', { token, ids_roles, ids_vistas, ids_unidades_medicas, id_unidad_medica_activa, nombre, apellido_paterno, apellido_materno, es_medico, configuracion_doctor, es_admin, vinculado, expediente_validado })
        resolve()
      })
      .catch(error => {
        console.log('refresh token error')
        console.log(error)
        commit('logout')
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        localStorage.removeItem('ids_roles')
        localStorage.removeItem('ids_vistas')
        localStorage.removeItem('ids_unidades_medicas')
        localStorage.removeItem('id_unidad_medica_activa')
        localStorage.removeItem('nombre')
        localStorage.removeItem('apellido_paterno')
        localStorage.removeItem('apellido_materno')
        localStorage.removeItem('es_medico')
        localStorage.removeItem('configuracion_doctor')
        localStorage.removeItem('es_admin')
        localStorage.removeItem('vinculado')
        localStorage.removeItem('expediente_validado')
        delete axios.defaults.headers.common['Authorization']  
        router.push("/")
        reject()
      })
    })
  }
}
