<template>
  <div>
    <v-row class="ma-0 pa-0">
      <v-col cols="12" class="ma-0 pa-0">
        <v-text-field
          v-model="url"
          :label="label"
          :dense="dense"
          :hide-details="hideDetails"
          :outlined="outlined"
          :error-messages="localError"
          @input="validate"
          required
          :error="errorMessages != null && errorMessages.length > 0"
        ></v-text-field>
        <span v-if="errorMessages != null && errorMessages.length > 0" class="red--text">{{errorMessages}}</span>
      </v-col>
      <v-col cols="12" class="ma-0 pa-0 text-center">
        <a
          @click="dialog = true"
        >
          Como obtener URL de la ubicación de Google Maps
        </a>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      max-width="60%"
      persistent
    >
      <v-card>
        <v-card-title>Instrucciones para compartir una ubicación en Google Maps</v-card-title>
        <v-card-text>
          <p>
            Sigue estos pasos para compartir una ubicación desde <strong>Google Maps</strong> en tu computadora:
          </p>
          <h3>Opción 1: Si el negocio o establecimiento está dado de alta</h3>
          <ol>
            <li>
              <strong>Abre Google Maps:</strong> Ingresa a 
              <a href="https://www.google.com.mx/maps" target="_blank">https://www.google.com.mx/maps</a> en tu navegador.
            </li>
            <li>
              <strong>Busca la ubicación:</strong> Escribe la dirección o el nombre del lugar en la barra de búsqueda y presiona <strong>Enter</strong>. También puedes hacer clic en un marcador ya existente en el mapa.
            </li>
            <li>
              <strong>Selecciona el marcador:</strong> Si buscaste un lugar o negocio, aparecerá un marcador en el mapa. Haz clic en él para que se muestre un cuadro de información.
            </li>
            <li>
              <strong>Haz clic en “Compartir”:</strong> Dentro del cuadro de información, busca el botón <strong>Compartir</strong>, representado por una flecha que apunta hacia la derecha.
              <v-img src="/img/google_maps/img1.png" height="500px" contain />
            </li>
            <li>
              <strong>Copia o comparte el enlace:</strong> Se abrirá una ventana emergente con un enlace. Haz clic en <strong>Copiar enlace</strong> para guardarlo en tu portapapeles o comparte directamente a través de las opciones disponibles.
              <v-img src="/img/google_maps/img2.png" height="500px" contain />
            </li>
          </ol>
          <br>
          <h3>Opción 2: Si el negocio o establecimiento no está dado de alta</h3>
          <ol>
            <li>
              <strong>Abre Google Maps:</strong> Ingresa a 
              <a href="https://www.google.com.mx/maps" target="_blank">https://www.google.com.mx/maps</a> en tu navegador.
            </li>
            <li>
              <strong>Encuentra la ubicación en el mapa:</strong> Desplázate en el mapa hasta ubicar el lugar exacto que deseas compartir.
            </li>
            <li>
              <strong>Haz clic derecho:</strong> Coloca el cursor sobre el lugar y haz clic derecho con el mouse.
            </li>
            <li>
              <strong>Selecciona “Compartir esta ubicación”:</strong> En el menú que aparece, elige esta opción.
              <v-img src="/img/google_maps/img3.png" height="500px" contain />
            </li>
            <li>
              <strong>Obtén el enlace:</strong> Se abrirá una ventana llamada <strong>Compartir</strong> con un enlace para la ubicación seleccionada. Haz clic en <strong>Copiar enlace</strong>.
              <v-img src="/img/google_maps/img2.png" height="500px" contain />
            </li>
          </ol>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="green"
            text
            block
            outlined
            @click="dialog = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Google Maps URL', // Valor por defecto para el label
    },
    dense: {
      type: Boolean,
      default: false, // Valor por defecto si es denso o no
    },
    hideDetails: {
      type: Boolean,
      default: true, // Valor por defecto para ocultar detalles
    },
    outlined: {
      type: Boolean,
      default: false, // Valor por defecto para outline
    },
    errorMessages: {
      type: [String, Array],
      default: () => []
    },
  },
  data() {
    return {
      url: this.value,
      localError: [],
      dialog: false,
    };
  },
  watch: {
    value(newValue) {
      this.url = newValue;
    },
    url(newUrl) {
      this.$emit('input', newUrl);
    },
  },
  methods: {
    validate() {
      this.localError = [];
      if (!this.url) {
        this.localError.push('La URL es requerida');
      } else if (
        !/^(https:\/\/)?(www\.)?(google\.)?(com|mx|co|es|de|fr|it|uk)?\/maps|^https:\/\/maps\.app\.goo\.gl/.test(
          this.url
        )
      ) {
        this.localError.push('Debe ser una URL válida de Google Maps');
      }
    }
  },
};
</script>
