<template>
  <div
    class="pa-3 ma-3"
  >
    <v-alert-dialog
      ref="alert"
    >
    </v-alert-dialog>
    <v-dialog
      max-width="65%"
      v-model="dialogAddEdit"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ editedItem.id_proveedor_cuenta_internacional === null ? "Crear" : "Editar" }} Cuenta Internacional
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="editedItem.banco"
                label="Banco *"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="editedItem.nombre_beneficiario"
                label="Nombre del Beneficiario *"
                outlined
                dense
                hide-details
                @input="onInputNombreBeneficiario"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="editedItem.clave_int"
                label="Clave Int *"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="editedItem.cuenta"
                label="Cuenta *"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="editedItem.swift"
                label="SWIFT"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="editedItem.isbn"
                label="ISBN"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                label="Moneda *"
                :items="monedas"
                item-value="id_moneda"
                item-text="nombre"
                v-model="editedItem.id_moneda"
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeDialogAddEdit">Cancelar</v-btn>
          <v-btn color="red" text @click="saveDialogAddEdit" :loading="loadingSave">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      max-width="65%"
      v-model="dialogDelete"
      persistent
    >
      <v-card>
        <v-card-title>
          Eliminar Cuenta Internacional
        </v-card-title>
        <v-card-text>
          ¿Está seguro de que desea eliminar la cuenta del beneficiario llamado "{{ editedItem.nombre_beneficiario }}"? Esta acción es permanente y no se puede revertir.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="closeDialogDelete">Cancelar</v-btn>
          <v-btn color="red" text @click="saveDialogDelete" :loading="loadingDelete">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row v-if="enableAddUpdate">
      <v-col cols="12">
        <v-btn
          color="primary"
          @click="openDialogAddEdit(null)"
          small
        >
          Crear Cuenta Internacional
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :items="items"
          :headers="headersFiltrados"
          :loading="loading"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="openDialogAddEdit(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              class="mr-2"
              @click="openDialogDelete(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:[`item.traza`]="{ item }">
            {{item.nombre_usuario}} a las {{item.actualizado}}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios'
import VAlertDialog from '@/components/VAlertDialog.vue';

export default {
  components: {
    VAlertDialog
  },
  data: () => ({
    dialogAddEdit: false,
    loading: false,
    items: [],
    headers:[
      {
        text: "Banco",
        value: "banco",
        align: "center",
        width: "15%"
      },
      {
        text: "Beneficiario",
        value: "nombre_beneficiario",
        align: "center",
        width: "20%"
      },
      {
        text: "Clave Int",
        value: "clave_int",
        align: "center",
        width: "15%"
      },
      {
        text: "Cuenta",
        value: "cuenta",
        align: "center",
        width: "15%"
      },
      {
        text: "Moneda",
        value: "moneda",
        align: "center",
        width: "15%"
      },
      {
        text: "Creado / Actualizado por",
        value: "traza",
        align: "center",
        width: "15%"
      },
      {
        text: "Acciones",
        value: "actions",
        align: "center",
        width: "10%"
      }
    ],
    editedItem: {
      id_proveedor_cuenta_internacional: null,
      banco: "",
      nombre_beneficiario: "",
      clave_int: "",
      cuenta: "",
      swift: "",
      isbn: "",
      id_moneda: null,
    },
    defaultEditedItem: {
      id_proveedor_cuenta_internacional: null,
      banco: "",
      nombre_beneficiario: "",
      clave_int: "",
      cuenta: "",
      swift: "",
      isbn: "",
      id_moneda: null,
    },
    loadingSave: false,
    dialogDelete: false,
    loadingDelete: false,
    monedas: [],
    loadingMonedas: false
  }),
  mounted() {
    this.load();
  },
  props: {
    id_proveedor: {
      type: String,
      default: "",
    },
    enableAddUpdate: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    headersFiltrados(){
      if(this.enableAddUpdate) return this.headers;
      else return this.headers.filter(x => x.value != 'actions'); 
    }
  },
  methods: {
    load(){
      this.getItems();
      this.getMonedas();
    },
    getItems(){
      this.loading = true;
      axios
        .get(`/Pagos/ListarCuentasInternacionales/${this.id_proveedor}`)
        .then(response => {
          this.items = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        })
    },
    getMonedas(){
      this.loadingMonedas = true;
      axios
        .get('/Catalogos/ListarMonedas')
        .then(response => {
          this.monedas = response.data;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingMonedas = false;
        })
    },
    openDialogAddEdit(editItem = null){
      if(editItem != null) this.editedItem = Object.assign({}, editItem);
      else this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogAddEdit = true;
    },
    closeDialogAddEdit(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogAddEdit = false;
    },
    saveDialogAddEdit(){
      if (this.editedItem.banco == null || this.editedItem.banco.length === 0 || this.editedItem.banco.length > 256) {
        this.$refs.alert.setMessage("", "Debe de Ingresar un Banco entre 1 y 256 caracteres");
        this.$refs.alert.open();
        return;
      }
      
      if (this.editedItem.nombre_beneficiario == null || this.editedItem.nombre_beneficiario.length === 0 || this.editedItem.nombre_beneficiario.length > 256) {
        this.$refs.alert.setMessage("", "Debe de Ingresar un Nombre del Beneficiario entre 1 y 256 caracteres");
        this.$refs.alert.open();
        return;
      }

      if (this.editedItem.clave_int == null || this.editedItem.clave_int.length === 0 || this.editedItem.clave_int.length > 18) {
        this.$refs.alert.setMessage("", "Debe de Ingresar una Cuenta Int entre 1 y 18 caracteres");
        this.$refs.alert.open();
        return;
      }

      if (this.editedItem.cuenta == null || this.editedItem.cuenta.length === 0 || this.editedItem.cuenta.length > 18) {
        this.$refs.alert.setMessage("", "Debe de Ingresar una Cuenta entre 1 y 18 caracteres");
        this.$refs.alert.open();
        return;
      }

      if ((this.editedItem.swift == null || this.editedItem.swift.length === 0) && (this.editedItem.isbn == null || this.editedItem.isbn.length === 0)) {
        this.$refs.alert.setMessage("", "Debe de Ingresar al menos uno de los campos SWIFT o ISBN");
        this.$refs.alert.open();
        return;
      }

      if (this.editedItem.id_moneda == null) {
        this.$refs.alert.setMessage("", "Debe de Seleccionar una Moneda");
        this.$refs.alert.open();
        return;
      }

      this.loadingSave = true;

      axios({
        method: this.editedItem.id_proveedor_cuenta_internacional === null ? 'POST' : 'PUT',
        url: this.editedItem.id_proveedor_cuenta_internacional === null ? '/Pagos/CrearCuentaInternacional' : `/Pagos/EditarCuentaInternacional/${this.editedItem.id_proveedor_cuenta_internacional}`,
        data: {
          id_proveedor: this.id_proveedor,
          banco: this.editedItem.banco,
          nombre_beneficiario: this.editedItem.nombre_beneficiario,
          clave_int: this.editedItem.clave_int,
          cuenta: this.editedItem.cuenta,
          swift: this.editedItem.swift,
          isbn: this.editedItem.isbn,
          id_moneda: this.editedItem.id_moneda
        }
      })
      .then(() => {
        this.getItems();
        this.closeDialogAddEdit();
      })
      .catch(error => {
        if(error.response.status == 409){
          this.$refs.alert.setMessage("", "Existe una Cuenta Internacional con la misma Clave Int o Cuenta.");
          this.$refs.alert.open();
          return;
        }
      })
      .finally(() => {
        this.loadingSave = false;
      });
    },
    openDialogDelete(editItem){
      this.editedItem = Object.assign({}, editItem);
      this.dialogDelete = true;
    },
    closeDialogDelete(){
      this.editedItem = Object.assign({}, this.defaultEditedItem);
      this.dialogDelete = false;
    },
    saveDialogDelete(){
      this.loadingDelete = true;
      axios
        .delete(`/Pagos/EliminarCuentaInternacional/${this.editedItem.id_proveedor_cuenta_internacional}`)
        .then(() => {
          this.closeDialogDelete();
          this.getItems();
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loadingDelete = false;
        });
    },
    reset(){
      this.items = [];
    },
    onInputNombreBeneficiario(value) {
      this.editedItem.nombre_beneficiario = this.cleanInput(value);
    },
    cleanInput(value) {
      const map = {
        'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u',
        'Á': 'A', 'É': 'E', 'Í': 'I', 'Ó': 'O', 'Ú': 'U',
        'ä': 'a', 'ë': 'e', 'ï': 'i', 'ö': 'o', 'ü': 'u',
        'Ä': 'A', 'Ë': 'E', 'Ï': 'I', 'Ö': 'O', 'Ü': 'U',
        'ñ': 'n', 'Ñ': 'N'
      };

      return value
        .split('')
        .map(char => map[char] || char) // Reemplaza caracteres con el mapeo
        .filter(char => /^[A-Za-z0-9 ]$/.test(char)) // Filtra solo letras, números y espacios
        .join('');
    }
  }
}
</script>
