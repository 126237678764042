<template>
  <v-col :cols="cols" :md="md" class="py-2">
    <v-col cols="12" class="d-flex align-center pa-0 ma-0">
      <v-check
        v-model="localErrorState"
        dense
        hide-details
        indeterminate
      >
      </v-check>
      <div
      :style="{
        cursor: 'pointer',
        display: 'block',
        width: '100%',
        padding: '6px 12px',
        border: '1px solid',
        borderRadius: '4px',
        textAlign: 'center',
        fontSize: '14px',
        lineHeight: '20px',
        color: localErrorState === false ? 'red' : '#A96419', /* Cambia color en estado de error */
        backgroundColor: 'transparent',
        fontWeight: '500',
        textTransform: 'uppercase',
        borderColor: localErrorState === false ? 'red' : '#A96419', /* Borde cambia en estado de error */
        transition: 'all 0.3s ease',
      }"
      @click="viewDocument"
    >
      VISUALIZAR {{ clearStrings(label) }}
    </div>

    </v-col>

    <v-col
      cols="12"
      v-if="localErrorState === false"
      class="ma-0 pa-0 py-2"
    >
      <v-textarea
        :label="textareaLabel"
        dense
        outlined
        hide-details
        v-model="localTextareaModel"
        auto-grow
        rows="1"
      ></v-textarea>
    </v-col>
    <v-dialog
      v-model="dialog"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Visualizacion de {{clearStrings(label)}}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-row class="pa-0 ma-0">
          <v-col cols="2" style="align-content: center !important;">
            <v-row class="ma-0 pa-0">
              <v-col cols="12" class="ma-0 pa-0 text-center text-subtitle-2">
                Resultado del Documento
              </v-col>
              <v-col cols="12" class="d-flex align-center pa-0 ma-0 justify-content-center">
                <v-check
                  v-model="localErrorState"
                  dense
                  hide-details
                  indeterminate
                >
                </v-check>
              </v-col>
              <v-col
                cols="12"
                v-if="localErrorState === false"
                class="ma-0 pa-0 py-2"
              >
                <v-textarea
                  :label="textareaLabel"
                  dense
                  outlined
                  hide-details
                  v-model="localTextareaModel"
                  auto-grow
                  rows="2"
                ></v-textarea>
              </v-col>

            </v-row>
          </v-col>
          <v-col cols="10">
            <v-row class="ma-0 pa-0">
              <v-col cols="12"
                v-if="showPDF"
              >
                <div class="pdf-container">
                  <vue-pdf-embed
                    class="pdf-viewer"
                    :source="url"
                  >
                  </vue-pdf-embed>
                </div>
              </v-col>
              <v-col v-else cols="12">
              <v-img
                :src="url"
                contain
                max-height="85vh"
              ></v-img>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import VCheck from '@/components/VCheck.vue';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
  name: "DownloadButtonWithTextarea",
  components: {
    VCheck,
    'vue-pdf-embed': VuePdfEmbed,
  },
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    downloadUrl: {
      type: String,
      required: true,
    },
    textareaLabel: {
      type: String,
      default: "Anomalía",
    },
    errorState: {
      type: Boolean,
      default: null,
    },
    cols: {
      type: Number,
      default: 12,
    },
    md: {
      type: Number,
      default: 12,
    },
    token: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    currentLocalTextareaModel: "",
    url: null,
    showPDF: false
  }),
  emits: ['update:textareaModel', 'update:errorState'],
  computed: {
    localErrorState: {
      get() {
        return this.errorState;
      },
      set(value) {
        this.$emit('update:errorState', value);
      }
    },
    localTextareaModel: {
      get() {
        return this.currentLocalTextareaModel;
      },
      set(value) {
        this.currentLocalTextareaModel = value;
        this.$emit('update:textareaModel', value);
      }
    }
  },
  methods: {
    getURL(){
      return `${this.downloadUrl}/${this.value}?jwt=${this.token}`;
    },
    downloadDocument() {
      window.open(this.getURL(), '_blank');
    },
    viewDocument() {
      this.dialog = true;
      this.showPDF = this.isPdfFile(this.value);
      this.url = this.getURL();
    },
    closeDocument() {
      this.dialog = false;
      this.url = null;
    },
    clearStrings(inputString) {
      return inputString.replace(/ \*/g, '');
    },
    isPdfFile(fileName) {
      return fileName.toLowerCase().endsWith('.pdf');
    }
  },
};
</script>


<style scoped>
.pdf-container {
  background-color: #f5f5f5; /* Fondo gris claro */
  padding: 16px; /* Margen interno */
  border-radius: 8px; /* Bordes redondeados */
  max-height: 85vh; /* Altura máxima del contenedor */
  overflow: auto; /* Scroll en caso de contenido adicional */
}

.pdf-viewer {
  width: 100%;
  height: calc(100% - 32px); /* Ajuste de altura con respecto al padding */
  max-height: 100%;
}
</style>