<template>
  <div style=" justify-items: center;">
    <v-container class="ma-0 pa-0">
      <v-row class="pa-0 ma-0">
        <v-col cols="12">
          <div @click="viewDocument" class="custom-btn primary--text outlined-border">
            <v-icon
              small
              color="primary"
            >
              mdi-eye
            </v-icon>
            Visualizar {{ label }}
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-model="dialog"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Visualizacion de {{clearStrings(label)}}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-row class="pa-0 ma-0">
          <v-col cols="12"
            v-if="showPDF"
          >
            <div class="pdf-container">
              <vue-pdf-embed
                class="pdf-viewer"
                :source="url"
              >
              </vue-pdf-embed>
            </div>
          </v-col>
          <v-col v-else cols="12">
          <v-img
            :src="url"
            contain
            max-height="85vh"
          ></v-img>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.custom-btn {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  font-weight: 500;
  white-space: normal;
  width: 100%;
  line-height: 1.2em;
  background-color: transparent;
}

/* Aplica el borde outlined con el color primario de Vuetify */
.outlined-border {
  border: 1px solid;
  border-color: var(--v-primary-base); /* Utiliza la variable de color primario */
}

.pdf-container {
  background-color: #f5f5f5; /* Fondo gris claro */
  padding: 16px; /* Margen interno */
  border-radius: 8px; /* Bordes redondeados */
  max-height: 85vh; /* Altura máxima del contenedor */
  overflow: auto; /* Scroll en caso de contenido adicional */
}

.pdf-viewer {
  width: 100%;
  height: calc(100% - 32px); /* Ajuste de altura con respecto al padding */
  max-height: 100%;
}
</style>

<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
export default {
  name: "DownloadButtonWithTextarea",
  components: {
    'vue-pdf-embed': VuePdfEmbed,
  },
  props: {
    value: {
      type: [String],
      default: null
    },
    urlBase: {
      type: String,
      required: true,
      default: '/api/Proveedores/DescargarDocumento'
    },
    label: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      file: null,
      dialog: false,
      url: null,
      showPDF: false
    };
  },
  computed: {
    isDownloadable() {
      return this.value && typeof this.value === 'string' && (this.file == null);
    },
    fileUrl() {
      return this.isDownloadable ? `${this.urlBase}${this.value}?jwt=${this.$store.state.token}` : null;
    }
  },
  methods: {
    downloadFile() {
      if (this.fileUrl) {
        window.open(this.fileUrl, '_blank');
      }
    },
    viewDocument() {
      this.dialog = true;
      this.showPDF = this.isPdfFile(this.value);
      this.url = this.getURL();

      console.log(this.url)
    },
    closeDocument() {
      this.dialog = false;
      this.url = null;
    },
    clearFile() {
      this.$emit('input', null);
      this.file = null;
    },
    handleFileChange() {
      if (this.file) {
        console.log(this.file);
        this.$emit('input', this.file); // Emite el archivo actualizado
      }
    },
    isPdfFile(fileName) {
      return fileName.toLowerCase().endsWith('.pdf');
    },
    clearStrings(inputString) {
      return inputString.replace(/ \*/g, '');
    },
    getURL(){
      return `${this.urlBase}/${this.value}?jwt=${this.$store.state.token}`;
    },
  }
};
</script>
